import React, {Fragment} from 'react';
import SEO from '../components/SEO/SEO';
import {graphql} from 'gatsby';
import ReactMarkdown from 'react-markdown';
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';

import '../scss/index.scss';
import '@splidejs/react-splide/css/core';
import Navigation from "../components/Navigation/Navigation";

const Vakantiewoningen = ({ data }) => {
  const { allHeroJson: hero } = data;
  const { allRentalsJson: rentals } = data;

  const logo = hero.nodes[0].logo;

  return (
    <>
      <SEO
        title="Vakantie woningen"
        description="Een prachtige camping in Zeeland. Mooi gelegen bij Zonnemaire en op steenworp afstand van Brouwershaven en Zierikzee."
        keywords={['Camping', 'Oud-Bommenede']}
      />

      <Navigation logo={logo} isVacancy />

      <section className="section" id="vacancy">
        <div className="wrapper">
          <header className="section__header">
            <h3>Vakantie woningen</h3>
          </header>
          <div className="grid">
            {rentals && rentals.nodes.map((rental, id) => {
              const prettyName = rental.chalet.split('-');

              for (let i = 0; i < prettyName.length; i++) {
                prettyName[i] = prettyName[i][0].toUpperCase() + prettyName[i].substr(1) + ' ';
              }

              return (
                <Fragment key={id}>
                  <div className="block block--image">
                    <Splide hasTrack={ false } aria-label="ChaletImages">
                      <SplideTrack>
                        {rental.galleryImages && rental.galleryImages.map((item, id) => {
                          return (
                            <SplideSlide key={id}>
                              <img src={item} height={480} alt={item} />
                            </SplideSlide>
                          )
                        })}
                      </SplideTrack>
                      <div className="splide__arrows">
                        <button className="splide__arrow splide__arrow--prev">
                          <span className="material-icons-round">chevron_left</span>
                        </button>
                        <button className="splide__arrow splide__arrow--next">
                          <span className="material-icons-round">chevron_right</span>
                        </button>
                      </div>
                    </Splide>
                  </div>
                  <div className="block block--grey block--center">
                    <h5>{rental.type}</h5>
                    <h4>{prettyName}</h4>
                    <ReactMarkdown children={rental.description} />
                    <a className="block-cta button button--primary"
                       href={`https://www.huurkalender.nl/vacancy/${rental.chalet}-${rental.huurkalender}.html`} target="_blank">
                      Bekijk beschikbaarheid
                    </a>
                  </div>
                </Fragment>
              )
            })}
          </div>
        </div>
      </section>

      <section className="section section--border-top" id="contact">
        <div className="wrapper">
          <div className="grid grid--1-1-1">
            <div className="block block--green grid--contact-1">
              <div className="block-spacing">
                <h4 className="block-headline">Openingstijden camping</h4>
                <p className="block-text">Geopend van 15 maart t/m 1 november<br />Aankomsttijd vanaf 12:00<br />Vertrektijd uiterlijk 12:00</p>
              </div>
              <div className="block-spacing">
                <h4 className="block-headline">De receptie</h4>
                <p className="block-text">Geopend van 09:00 – 17:00 en 19:00 – 20:00</p>
              </div>
              <div className="block-spacing">
                <h4 className="block-headline">Reserveren</h4>
                <p className="block-text">U kunt telefonish reserveren voor de camping of een vakantie woning. Voor een van de vakantie woningen kunt u ook online reserveren.</p>
              </div>
            </div>
            <div className="block block--grey grid--contact-2">
              <div>
                <h4 className="block-headline">Ons adres</h4>
                <address className="block-text">Kijkuitsedijk 1<br />4316 PN<br />Zonnemaire</address>
              </div>
            </div>
            <div className="block block--white block--shadow grid--contact-3">
              <div>
                <h4 className="block-headline">Neem contact op</h4>
                <p className="block-text">
                  +31 (0) 6 20 354 744
                </p>
                <p className="block-text">
                  info@oudbommenede.nl
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="footer">
        <div className="wrapper">
          &copy; campingoudbommenede.nl
        </div>
      </footer>
    </>
  )
};

export const query = graphql`
    query VacancyQuery {
        allHeroJson {
            nodes {
                logo
            }
        }
        allRentalsJson {
            nodes {
                chalet
                description
                type
                huurkalender
                galleryImages
            }
        }
    }
`;

export default Vakantiewoningen;